import React from "react"

import Layout from "../components/layout"
import {graphql, Link} from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo";

const StylistsPage = ({data: {allDatoCmsStylist: stylists, datoCmsHomepage: p}}) => {
  const sortedStylists = stylists
    .edges
    .map(v => v.node)


  const compare = (a, b) => {
    if (a < b) {
      return -1
    }
    if (a > b) {
      return 1
    }
    return 0
  }
  sortedStylists.sort((a, b) => {
    if (a.city === null && b.city === null) {
      return compare(a.name, b.name)
    }
    if (a.city === null) {
      return 1;
    }
    if (b.city === null) {
      return -1;
    }
    const r = compare(a.city.name, b.city.name);
    if (r === 0) {
      return compare(a.name, b.name)
    }
    return r;
  })
  const initials = name => {
    const parts = name.split(' ')
    return parts[0] + ' ' + (parts.length > 1 ? parts[1].slice(0, 1) : '');
  }
  return (
    <Layout>
      <SEO title="Stylists" keywords={['shopping', 'stylist']} seo={p.seoMetaTags}/>
      <div className='flex flex-col items-center'>
        <h1 className='font-title font-bold text-4xl uppercase mt-8 mb-2 text-center tracking-wider'>Stylists from
          around the world</h1>
        <div className='my-8 flex flex-wrap justify-center w-full'>
          {sortedStylists
            .map((stylist) =>
              (
                <Link className='flex-grow mx-2 my-2 no-underline' to={'/stylists/' + stylist.originalId + '/'}
                      key={stylist.id}>
                  <Img className='mb-2' style={{minWidth: 200, maxWidth: 450}} fluid={stylist.avatar.fluid}/>
                  <div className="flex flex-col">
                    <span
                      className='uppercase text-black font-title font-light text-base'>{initials(stylist.name)}</span>
                    <div className="flex justify-between">
                      {stylist.city && <span
                        className='text-gray-600 font-sans font-light text-base'>{stylist.city.name}</span>}
                      <span className='text-gray-600 font-sans font-light text-base'>{stylist.experience}</span>
                    </div>
                  </div>
                </Link>
              )
            )}
        </div>
      </div>
    </Layout>
  );
}

export default StylistsPage

export const query = graphql`
    query Stylists {
        datoCmsHomepage {
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
        }
        allDatoCmsStylist(limit:500,filter: {visible: {eq: true}, , avatar: {isImage: {eq: true}}},sort:{fields:[city___name, name]}) {
            edges {
                node {
                    id
                    originalId
                    name
                    experience
                    city {
                        name
                    }
                    avatar {
                        fluid(maxWidth: 400, imgixParams: {fit: "crop", h: "400", w: "400"}) {
                            ...GatsbyDatoCmsFluid
                        }
                    }
                }
            }
        }
    }
`
